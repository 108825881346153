<template>
    <div class="message-wrapper">
        <div :class="['message-content', {show: isShow}]">
            <div class="message-icon"></div>
            <div class="message-text">{{ message }}</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Message",
        data() {
            return {
                message: null,
                isShow: false,
                duration: 3000,
            }
        },
        methods: {
            error(message, duration = this.duration) {
                this.message = message
                this.isShow = true
                setTimeout(() => {
                    this.isShow = false
                }, duration)
            }
        },
    }
</script>

<style scoped lang="less">
    .message-wrapper {
        display:flex;
        justify-content:center;
        position:absolute;
        top:0;
        width:100%;
        .message-content {
            display:flex;
            align-items:center;
            border-radius:8px;
            padding:12px 16px;
            margin-top:12px;
            box-shadow:0 0 1px rgba(0, 0, 0, 0.3), 0 4px 14px rgba(0, 0, 0, 0.1);
            background-color:#FFFFFF;
            transform:translateY(-100%);
            opacity:0;
            transition:transform 0.3s ease-out, opacity 0.3s ease-out;
            &.show {
                transform:translateY(0%);
                opacity:1;
            }
            .message-icon {
                width:20px;
                height:20px;
                background-image:url("../../assets/images/icons/ic_text_delete.png");
                background-size:100% 100%;
            }
            .message-text {
                margin:0 12px;
                font-size:14px;
                font-weight:700;
                line-height:20px;
            }
        }
    }
</style>
import Vue from 'vue'
import Template from './Template'

let MessageConstructor = Vue.extend(Template)

const Message = function (options) {
    if (Vue.prototype.$isServer) return

    options = options || {}
    if (typeof options === 'string') {
        options = {
            message: options
        }
    }
    let parent
    if (typeof options.target === 'string') {
        parent = document.querySelector(options.target)
    }
    parent = parent || document.body

    let instance = new MessageConstructor({
        data: options
    })

    instance.$mount()
    parent.style.position = 'relative'
    parent.appendChild(instance.$el)

    return instance
}

export default Message
<template>
    <div class="login">
        <asc-header></asc-header>
        <div class="content">
            <div class="info">
                <div class="title">创作者服务平台</div>
                <div class="sub-title">解锁创作者专属功能，助力创作者高效运营！</div>
                <div class="pic-wall">
                    <div style="flex:0 0 280px;" class="pic-wall-wrapper pic1"></div>
                    <div style="flex:0 0 180px;" class="pic-wall-wrapper pic2"></div>
                    <div style="flex:0 0 250px;">
                        <div class="pic-wall-wrapper pic3"></div>
                        <div class="pic-wall-wrapper pic4"></div>
                    </div>
                </div>
            </div>
            <div class="login-form">
                <div class="form-title">短信登录</div>
                <div class="form-body">
                    <div class="input">
                        <div class="phone">
                            <div class="area-code">+86</div>
                            <input type="text" placeholder="手机号" @focus="inputFocus" @blur="inputBlur" v-model="displayPhone">
                        </div>
                        <div class="code">
                            <input type="text" placeholder="验证码" @focus="inputFocus" @blur="inputBlur" v-model="authCode">
                            <div @click="getAuthCode">{{ codeMessage }}</div>
                        </div>
                    </div>
                    <div class="btn">
                        <button class="active" @click="doLogin">登录</button>
                        <div class="tip">
                            <span>登录即视为同意</span>
                            <a href="/user_agreement" target="_blank">用户协议</a>
                            <span>和</span>
                            <a href="/privacy_agreement" target="_blank">隐私政策</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <qilv-footer themes="transparent"></qilv-footer>
    </div>
</template>

<script>
    import Message from '../../components/message'

    export default {
        name: "Login",
        data() {
            return {
                authCode: null,
                phone: null,
                codeMessage: null,
                allowGetAuthCode: true,
                $message: null,
            }
        },
        computed: {
            displayPhone: {
                get: function () {
                    let result = /^(\d{1,3})(\d{1,4})?(\d*)$/.exec(this.phone)
                    return result == null
                        ? this.phone
                        : result[1]
                        + (result[2] === undefined ? '' : ' ' + result[2])
                        + (result[3] === undefined ? '' : ' ' + result[3])
                },
                set: function (val) {
                    this.phone = val.replace(/\s/g, '')
                }
            }
        },
        methods: {
            doLogin() {
                // 数据检查
                if (!/^1\d{10}$/.test(this.phone) || !/^\d{6}$/.test(this.authCode)) {
                    this.$message.error('手机号或验证码错误')
                    return
                }
                // 请求登录
                this.$http.post('/api/auth/web_phone_login', {
                    area_code: '+86',
                    phone: this.phone,
                    auth_code: this.authCode,
                }).then(res => {
                    localStorage.setItem('access_token', res.data.access_token)
                    localStorage.setItem('access_token_expire', res.data.access_token_expire)
                    this.$store.commit('login', {
                        loginState: true,
                        username: res.data.username,
                        avatar: res.data.avatar,
                        access_token: res.data.access_token
                    })
                    this.$router.push('/asc/video_list').catch(err => err)
                })
            },
            inputFocus(evt) {
                evt.currentTarget.parentElement.classList.add('active')
            },
            inputBlur(evt) {
                evt.currentTarget.parentElement.classList.remove('active')
            },
            getAuthCode() {
                if (!this.allowGetAuthCode) return
                // 判断手机号格式
                if (!/^1\d{10}$/.test(this.phone)) {
                    this.$message.error('请填写正确的手机号！')
                    return
                }
                this.$http.post('/api/auth/send_code', {phone: this.phone, area_code: '+86'})
                this.authCodeCountDown()
            },
            authCodeCountDown(seconds = 60) {
                this.allowGetAuthCode = false;
                let timer = setInterval(() => {
                    localStorage.setItem('authCodeTimeout', new Date(Date.now() + seconds * 1000).toString())
                    this.codeMessage = --seconds + '后再次获取'
                    if (seconds <= 0) {
                        clearInterval(timer)
                        this.codeMessage = '获取短信验证码'
                        this.allowGetAuthCode = true
                    }
                }, 1000)
            }
        },
        mounted() {
            this.$message = new Message({target: '.login > div.content'})
        },
        created() {
            // 判断是否还存倒计时？
            let authCodeTimeout = localStorage.getItem('authCodeTimeout')
            if (authCodeTimeout != null) {
                let timeout = new Date(authCodeTimeout)
                if (timeout.getTime() > Date.now()) {
                    let seconds = Math.floor((timeout.getTime() - Date.now()) / 1000)
                    this.authCodeCountDown(seconds)
                } else {
                    this.codeMessage = '获取短信验证码'
                }
            } else {
                this.codeMessage = '获取短信验证码'
            }
        }
    }
</script>

<style scoped lang="less">
    .login {
        width:100%;
        background-image:url("../../assets/images/acs_bg.png");
        background-size:100% 100%;
        display:flex;
        flex-direction:column;
        justify-content:space-between;
        .content {
            height:420px;
            display:flex;
            justify-content:space-between;
            .info {
                display:flex;
                flex-direction:column;
                .title {
                    height:50px;
                    font-size:36px;
                    font-weight:500;
                    line-height:50px;
                }
                .sub-title {
                    height:33px;
                    margin:12px 0 24px 0;
                    font-size:24px;
                    font-weight:400;
                    line-height:33px;
                    color:#868686;
                }
                .pic-wall {
                    display:flex;
                    flex:1;
                    justify-content:space-between;
                    gap:0 10px;
                    div {
                        flex:1;
                        border-radius:10px;
                        overflow:hidden;
                        background-color:white;
                    }
                    > div:last-child {
                        display:flex;
                        flex-direction:column;
                        gap:10px 0;
                        background-color:transparent;
                    }
                    &-wrapper {
                        background-size:100% 100%;
                        &.pic1 {background-image:url("../../assets/images/img_creator_sign_display1.png")}
                        &.pic2 {background-image:url("../../assets/images/img_creator_sign_display2.png")}
                        &.pic3 {background-image:url("../../assets/images/img_creator_sign_display3.png")}
                        &.pic4 {background-image:url("../../assets/images/img_creator_sign_display4.png")}
                    }
                }
            }
            .login-form {
                width:384px;
                background-color:white;
                border-radius:10px;
                padding:40px 32px;
                display:flex;
                flex-direction:column;
                box-shadow:0 0 1px rgba(0, 0, 0, 0.3), 0 4px 14px rgba(0, 0, 0, 0.1);
                .form-title {
                    height:22px;
                    margin-bottom:32px;
                    font-size:16px;
                    font-weight:500;
                    line-height:22px;
                }
                .form-body {
                    height:100%;
                    display:flex;
                    flex-direction:column;
                    justify-content:space-between;
                    input, button {
                        flex:1;
                        border:none;
                        background-color:transparent;
                        outline:none;
                        line-height:56px;
                    }
                    input[disabled] {cursor:not-allowed;}
                    .input {
                        > div {
                            height:56px;
                            font-size:14px;
                            line-height:54px;
                            padding:0 16px;
                            display:flex;
                            border:1px solid #F8F8F8;
                            border-radius:8px;
                            overflow:hidden;
                            background-color:#F8F8F8;
                            & + div {
                                margin-top:20px;
                            }
                            &.active {
                                border-color:#FE5038;
                            }
                        }
                        .area-code {
                            position:relative;
                            cursor:not-allowed;
                            padding-right:22px;
                            color:#ADADB1;
                            &::after {
                                content:" ";
                                width:6px;
                                height:6px;
                                margin:0 12px;
                                display:block;
                                position:absolute;
                                top:22px;
                                right:-5px;
                                border-width:0 0 2px 2px;
                                border-style:solid;
                                border-color:#9B9EA0;
                                border-radius:2px;
                                transform:rotate(-45deg);
                            }
                        }
                        .code div {cursor:pointer;}
                    }
                    .btn {
                        button {
                            width:100%;
                            height:56px;
                            margin-bottom:12px;
                            border-radius:8px;
                            background-color:#C6CACD;
                            font-size:16px;
                            color:#555555;
                            cursor:not-allowed;
                            &.active {
                                color:white;
                                background-color:#FE5038;
                                cursor:pointer;
                            }
                        }
                        .tip {
                            font-size:14px;
                            text-align:center;
                            span {
                                color:#ADADB1;
                            }
                        }
                    }
                }
            }
        }
    }
</style>
